import {defineStore} from 'pinia';
import {STORE_KEYS} from '~/stores/storeKeys';
import type {IProductDetails} from '~/utils/services/api';
import type {ICpArticle, ICpBreadcrumb} from '~/types';

interface ICpDetailsStore {
    productDetails: null | IProductDetails
    selectedQty: number
    renderedOnServer: boolean
    isLoading: boolean
    urlId: string
    productAlternative: null | IProductDetails
}

/**
 * Pinia store with very useful getters for the product details data
 * The intention is to encapsulate logic that is shared across different components of the Pdp Screen
 */
export const usePdp = defineStore(STORE_KEYS.Details, {
    state: (): ICpDetailsStore => ({
        productDetails: null,
        selectedQty: 1,
        renderedOnServer: false,
        isLoading: true,
        urlId: '',
        productAlternative: null,
    }),
    actions: {
        setPdp(pdp: IProductDetails) {
            this.productDetails = pdp;
        },
        setSelectedQty(qty: number) {
            this.selectedQty = qty;
        },
        setRenderedOnServer(renderedOnServer: boolean) {
            this.renderedOnServer = renderedOnServer;
        },
        setIsLoading(isLoading: boolean) {
            this.isLoading = isLoading;
        },
        setUrlId(urlId: string) {
            this.urlId = urlId;
        },
        setProductAlternative(pAlternative: IProductDetails) {
            this.productAlternative = pAlternative;
        },
    },
    getters: {
        /**
         * Handles the number of pieces selected, to later add them to the basket
         * */
        getSelectedQty(state): number {
            return state.selectedQty;
        },
        getProduct(state): ICpArticle | undefined {
          return state.productDetails?.article;
        },
        getProductId(state): string {
          return state.productDetails?.article?.id ?? '';
        },
        /**
         * Returns useful information about product stock, or the stock number.
         */
        getProductStock(state): {stock: number, lowStock:boolean, hasStock: boolean} {
            const stock = state.productDetails ? state.productDetails.article.stock : 0;
            return {
                stock,
                lowStock: stock < 10,
                hasStock: stock > 0,
            };
        },
        /**
         * Product is End-of-Life, meaning it is not, nor will be, available for sale any longer
         * */
        getProductIsEol(state): boolean {
          return state.productDetails ? state.productDetails.article.eol : false;
        },
        /**
         * Product is digital / downloadable
         * */
        getProductIsEsd(state): boolean {
          return state.productDetails ? state.productDetails.article.isEsd : false;
        },
        /**
         * Product type
         * */
        getProductType(state): string | null {
            return state.productDetails ? state.productDetails.article.type : null;
        },
        getProductDiscount(state) : { discount: string | number, discountAbsolute: string | boolean, discountPercent: number} {
            const discount: any = state.productDetails ? state.productDetails.article.offer.percent || 0 : 0;
            return {
                discount,
                discountAbsolute: state.productDetails?.article.offer.absolute ?? '0',
                discountPercent: discount !== 0 ? parseFloat(discount as string) : discount,
            };
        },
        getProductPictures(state): string[] {
            return state.productDetails?.article.pictures.map((pictureUrl) => pictureUrl.replace(/\/[SML]\//, '/M/')) ?? [];
        },
        getProductPrice(state): number {
            return Number(state.productDetails?.article.price) ?? 0;
        },
        getProductBreadcrumbs(state): Array<ICpBreadcrumb> {
            return state.productDetails?.breadCrumbs ?? [];
        },
        getSku(state): string {
            return state.productDetails?.article.sku ?? '';
        },
        getHasDiscount(state) {
            return state.productDetails?.article!.isOffer && state.productDetails?.article!.price !== state.productDetails?.article!.tPrice;
        },
        getProductAlternative(state): ICpArticle | undefined {
            return state.productAlternative?.article;
        },
    },
});
