import type {AsyncDataOptions} from '#app';
import {useAsyncData} from '#app';
import {QUERY_KEYS} from '~/utils/services/keys';
import type {UpdateBasketData} from '~/utils/services/api/basket/basket';
import {
    addToBasket,
    getBasket,
    getBasketAccessories,
    updateBasket,
    addVoucher,
    getDeliverySets,
} from '~/utils/services/api/basket/basket';
import {useCpUser} from '~/composables/useCpUser';
import {rejectQueryFn} from '~/utils';
import type {ICpBasketContents, IDeliverySet, ICpmUpgradesPayload} from '~/types';
import {useCheckoutStore} from '~/stores';
import {useGetPaypalInstallmentsMSI} from '~/composables/queries/paymentMethods';

export const useBasket = (options?: AsyncDataOptions<ICpBasketContents>) => {
    const user = useCpUser();
    return useAsyncData(QUERY_KEYS.user.basket(user.userEmail ?? ''), () => getBasket(), options);
};

export const useBasketCount = (options?: Omit<AsyncDataOptions<number>, 'transform'>) => {
    const user = useCpUser();
    return useAsyncData(
        QUERY_KEYS.user.basket(user.userEmail ?? ''),
        () => getBasket(),
        {
            ...options,
            transform: (response) => {
                return response.basket.numberOfItems;
            },
        },
    );
};

export const useAddToBasket = (product: string, amount: number, upgrades?: ICpmUpgradesPayload[]) => {
    const user = useCpUser();
    return useAsyncData(QUERY_KEYS.user.basketAdd(user.userId ?? ''), async () => {
        const response = await addToBasket(product, amount, upgrades);
        useBasket();
        return response;
    });
};

export const useUpdateBasket = (payload: UpdateBasketData, bindex?: string, reload = true) => {
    const user = useCpUser();
    return useAsyncData(QUERY_KEYS.user.basketAdd(user.userId ?? ''), async () => {
        const response = await updateBasket(payload, bindex);
        if (reload) {
    await useBasket();
}
        return response;
    });
};

export const useAddVoucher = (voucher: string) => {
    return useAsyncData(QUERY_KEYS.checkout.addVoucher, () => addVoucher(voucher));
};

export const useBasketAccessories = async () => {
    const user = useCpUser();
    const basketResp = await getBasket();
    return useAsyncData(QUERY_KEYS.basket.accessories(user.userEmail ?? '', ''), () => {
        if (!!basketResp?.basket.numberOfItems && basketResp.basket.numberOfItems > 0) {
            return rejectQueryFn();
        } else {
            return getBasketAccessories();
        }
    });
};

export const activeBasketDeliverySets = (data: ICpBasketContents) => {
    let inactive = true;
    if (data) {
        data.deliverySetGroups.forEach((group) => {
            if (group?.deliverySets[0]?.id !== 'envio-digital-instantaneo' && group?.deliverySets[0]?.id !== 'envio-digital') {
                group?.deliverySets?.forEach((item) =>
                    !item.deactivate ? (inactive = false) : null,
                );
            }
        });
    }
    // If only have a Digital product, we have delivery methods
    if (data.deliverySetGroups.length === 1 && (data.deliverySetGroups[0]?.deliverySets[0]?.id === 'envio-digital-instantaneo' || data.deliverySetGroups[0]?.deliverySets[0]?.id === 'envio-digital')) {
        inactive = false;
    }
    return !inactive;
};

export const getAnotherDeliverySetEnable = (data: ICpBasketContents) => {
    const selected = data.deliverySetGroups[0]?.deliverySets.find((a) => a.selected);

    let newDelivery: any = null;
    data.deliverySetGroups[0]?.deliverySets.forEach((item: IDeliverySet) => {
        if (!newDelivery && !item.deactivate && item.id !== selected?.id) {
            newDelivery = item.id;
        }
    });

    return newDelivery;
};

export const useIsDeliveryActive = (data: ICpBasketContents) => {
    return activeBasketDeliverySets(data as ICpBasketContents);
};

export const useSelectDeliveryMethod = (deliveryMethod: { id: string }) => {
    const user = useCpUser();
    return useAsyncData(QUERY_KEYS.user.basketUpdated(user.userId ?? ''), () => {
        if (!user.userEmail) {
            return rejectQueryFn();
        }
        return updateBasket({deliverySet: deliveryMethod.id});
    });
};

export const getBasketMSI = async (total: number) => {
    // Don't make request if basket is empty
    if (!total) {
        useCheckoutStore().paypalInstallmentsMSI = null;
        return;
    }
    const {data: installmentsData} = await useGetPaypalInstallmentsMSI(total, true);
    if (installmentsData.value) {
 useCheckoutStore().paypalInstallmentsMSI = installmentsData.value;
}
};

export const useDeliverySets = () => {
    return useAsyncData(QUERY_KEYS.checkout.deliverySets, () => getDeliverySets());
};
