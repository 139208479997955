import {$cpFetch} from '~/composables/useCpFetch';
import {basketPaths, vouchersPaths} from '~/utils/services/paths';
import type {IBasketAccessories, ICpBasketContents, ICpmUpgradesPayload, IDeliverySetData} from '~/types';

export const DeliveryMethods = {
    estafeta: 'oxidstandard',
    dhl: '5c1a15de411c6',
    paqueteExpress: '601d6a90cbaea',
};

export type UpdateBasketData =
    | {
    articles: Array<{ amount: number; id: string }>;
}
    | {
    paymentMethod: string;
}
    | {
    deliverySet: string;
}
    | {
    deleteMsgs: boolean;
};

type voucherResponse = | {
    status: string,
    msg: string,
    values?: {
        voucherNR: string,
        voucherMinVal: null | number
    }
} | {
    msg: string,
    code: number,
    errors: Array<
        {
            status: string,
            code: string,
            title: string,
            detail: string
        }
    >,
    meta: {
        messagesToDisplay: Array<any>
    }
}

export const getBasket = () => {
    return $cpFetch<ICpBasketContents>(basketPaths.all);
};

export const addToBasket = (product: string, amount: number, upgrades?: ICpmUpgradesPayload[]) => {
    const payload = [{
        id: product,
        amount,
        /** Alchemy Force upgrades **/
        additionalParameters: {upgrade: upgrades !== undefined ? upgrades : []},
    }];
    return $cpFetch<ICpBasketContents>(basketPaths.all, {
        method: 'POST',
        body: {
            articles: payload,
        },
        // Retry when call fails with 401, this is because the session is expired
        retry: 1,
        retryStatusCodes: [401],
        retryDelay: 500,
    });
};
/**
 * Updates basket information (payment method, delivery set and articles)
 * */
export const updateBasket = (payload: UpdateBasketData, bindex?: string) => {
    // bindex parameter no affect to other products in the shop, only help to AF products
    return $cpFetch(basketPaths.updateBasket(`${bindex}`), {
        method: 'PUT',
        body: payload,
    });
};

export const addVoucher = (voucher: string) => {
    return $cpFetch(vouchersPaths.add, {
        method: 'POST',
        parseResponse: (txt) => (txt === '' ? '' : JSON.parse(txt)),
        body: {
            voucher,
        },
    });
};

export const getBasketAccessories = () => {
    return $cpFetch<IBasketAccessories>(basketPaths.accessories);
};

export const getDeliverySets = () => {
    return $cpFetch<IDeliverySetData>(basketPaths.deliverySets);
};
