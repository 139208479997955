import type {
  ICatMan,
  ICpArticle,
  ISearchSuggestions,
} from '~/types';
import {catalogPaths} from '~/utils/services/paths';
import {$cpFetch} from '~/composables/useCpFetch';
import {rejectQueryFn} from '~/utils';

/**
 * Indicates the type of listing
 * - cat -> List by category
 * - man -> List by brand (manufacturer)
 * - search -> List by search term
 * */
export type CatalogType = 'cat' | 'man' | 'search';

export const getBrands = () => {
  return $fetch<Array<ICatMan>>(
    'https://www.cyberpuerta.mx/widget.php?cl=cpmobile_ajax&fnc=getRootManufacturers&skipSession=1',
  );
};

/**
 * Given the shop prefix and a slot name, returns an list of products and a title to display in a recommended section.
 * Some slots require extra parameters like: catPath, sku, userId or email; catPath and sku should be provided together.
 * @param params type ICatalogRecommendedParams
 */
export interface ICatalogRecommendedParams {
  shop: 'ab' | 'cp';
  slot: string;
  cache?: boolean;
  catPath?: string;
  sku?: string;
  slotName?: string;
  userId?: string;
}

export interface ICatalogRecommended {
  products: Array<{
    productid: string;
  }>;
  title: string;
}

export const getCatalogRecommended = async (
    params: ICatalogRecommendedParams,
) => {
  return await $cpFetch<ICatalogRecommended>(
      catalogPaths.recommended,
      {
        params: {...params, skipNosto: true},
      },
  );
};

export const getLastSeen = (excludedArticle = '') => {
  let params = {};
  if (excludedArticle) {
 params = {exclude: excludedArticle};
}
  return $cpFetch<ICpArticle[]>(catalogPaths.lastSeen, {
    params,
  });
};
export const addLastSeen = async (articleId: string) => {
  return await $cpFetch<any>(catalogPaths.lastSeen, {
    method: 'POST',
    body: {article: articleId},
  });
};

export const getCatalogSearchSuggestions = async (q: string, catId?: string, manId?: string) => {
  return await $cpFetch<ISearchSuggestions>(
    catalogPaths.search,
    {
      params: {q, catId, manId},
    },
  );
};
export const getArticleInfo = async (articleId: string[]) => {
  const options = {
      method: 'GET',
      withoutAuthorization: true,
      useMS: true,
  };
  // Keeps type Any because the ICpArticle is an embedded type in the response
  return await $cpFetch<any>(catalogPaths.getArticleInfo(articleId), options);
};
